.bottomTimerText{
  font-family: Nunito;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
  margin: 8px 0 0;
  color: rgba(98, 98, 111, 1);
}

.timerBlock {
  position: relative;
  width: 258px;
  height: 61px;
  border-radius: 16px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
  margin: 24px auto 0;
  box-shadow: 10px 15px 30px 0px rgba(175, 145, 193, 0.1), -4px -4px 4px 0px rgba(255, 255, 255, 0.5);
}

.timerBlock::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 16px; 
  padding: 1px;
  background: linear-gradient(98.35deg, rgba(140, 86, 255, 0.4) 22.71%, rgba(201, 86, 255, 0.4) 73.26%);
  mask: 
  linear-gradient(#fff 0 0) content-box, 
  linear-gradient(#fff 0 0);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
  z-index: -1;
}

.Page {
  background-color: #fff;
  padding: 32px 24px 80px;
}

.BackButton {
  display: flex;
  color: hsla(230, 40%, 14%, 1);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  align-items: center;
  gap: 4px;
}

.BackButtonContainer {
  border: 2px solid var(--Stroke-Light);
}

.BackButton svg {
  margin-top: 3px;
}

.HeaderContainer {
  text-align: center;
  margin-bottom: 32px;
}
.Header {
  font-size: 48px;
  line-height: 56px;
  font-weight: 800;
  margin-bottom: 12px;
}

.SubHeader {
  color: var(--Text-Black-Primary, #161B33);
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.hg {
  background: var(--Gradient-Accent, linear-gradient(98deg, #8C56FF 22.71%, #C956FF 73.26%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.TabsContainer {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.save40 {

}

.PlanContainer {
  width: 1082px;
  margin: auto;
  display: flex;
  gap: 25px;
}

.TopActions {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 12px;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltip_text {
  visibility: hidden;
  width: 316px;
  background-color: rgba(121, 72, 225, 1);
  color: rgba(255, 255, 255, 1);
  text-align: center;
  border-radius: 8px;
  padding: 16px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -158px;
  opacity: 0;
  transition: opacity 0.3s;

  font-family: PT Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.tooltip .tooltip_text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(121, 72, 225, 1) transparent transparent transparent; /* Make the arrow */
}

.tooltip:hover .tooltip_text,
.tooltip:focus .tooltip_text {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 1120px) {
  .PlanContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 362px;
  }

  .Page {
    background-color: #fff;
    height: 100vh;
    height: 100dvh;
    padding: 32px 16px 80px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (max-width: 481px) {
  .Header {
    font-size: 32px;
    line-height: 36px;
  }

  .SubHeader {
    font-size: 16px;
  }

  .PlanContainer {
    flex-direction: column;
    width: auto;
  }
  .TopActions {
    justify-content: start;
  }
}
