.removeBackgroundToggle {
    width: 100%;
    text-align: left;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pink_icon {
    background: linear-gradient(109.04deg, #ED638C 13.68%, #ED78BE 86.67%);
}

.plus_icon {
    fill: var(--Accent-Violet-Default);
}

.aiPanel {
    border-radius: 20px;
    padding: 16px 12px;
    background: rgba(245, 240, 255, 1);
    width: 100%;
    margin-bottom: 12px;
}

.link {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 700;
}

.linkContainer {
    margin-bottom: 8px;
    font-family: Nunito, sans-serif;
    line-height: 21.82px;
    color: rgba(22, 27, 51, 0.6);
    font-size: 16px;
}

.imgContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 6px;
}

.aiImageCheckbox {
    position: absolute !important;
    top: 4px;
    right: 4px;
}

.labelBlack {
    font-size: 16px;
    font-weight: 700;
    line-height: 21.82px;
    color: hsla(230, 40%, 14%, 1);
    font-family: Nunito, sans-serif;
    display: flex;
    gap: 8px;
}

.content_wrapper_element{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip .tooltip_text {
    visibility: hidden;
    width: 260px;
    background-color: rgba(121, 72, 225, 1);
    color: rgba(255, 255, 255, 1);
    text-align: center;
    border-radius: 8px;
    padding: 16px;
    position: absolute;
    z-index: 125;
    bottom: 125%;
    left: 190%;
    margin-left: -158px;
    opacity: 0;
    transition: opacity 0.3s;

    font-family: PT Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}

.tooltip .tooltip_text::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(121, 72, 225, 1) transparent transparent transparent ; /* Make the arrow */
}

.tooltip .tooltip_text_reverse {
    bottom: -280%;
}

.tooltip .tooltip_text_reverse::after {
    border-color: transparent transparent rgba(121, 72, 225, 1) transparent ; /* Make the arrow */
    top: -12%;
}

.tooltip .tooltip_text_displacement_left {
    left: -45%
}

.tooltip .tooltip_text_displacement_left::after {
    left: 68%
}

.tooltip:hover .tooltip_text,
.tooltip:focus .tooltip_text {
    visibility: visible;
    opacity: 1;
}


.aiImagePanel {
    display: flex;
    flex-flow: row wrap;
    height: 127px;
    width: 95px;
    border-radius: 8px;
    background: #fff;
    gap: 12px;
    align-items: center;
    justify-content: center;
    position: relative;
}

.aiImagePanel__withoutBackground{
    background: transparent;
}

.aiImagePanel_object {
    object-fit: contain;
    height: 72px;
    max-width: 100%;
    max-height: 100%;
    padding: 4px
}

.aiImagePanel_information {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
}

.formControlLabel {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.labelContainer {
    display: flex;
    align-items: center;
}

.labelWrapper {
    display: flex;
    align-items: center;
}


.toggleLabel {
    margin-right: 18px;
    justify-self: stretch;
    line-height: 24px;
}

.panel_heading {
    padding: 24px 0 20px 24px;
}

.beta {
    margin-bottom: 24px;
    background: #F5F0FF;
    padding: 12px;
    border-radius: 8px;
    width: 310px;
    font-family: Nunito, sans-serif;
}

.betaText {
    font-size: 12px;
}

.removeBackgroundIcon {
    fill: var(--Accent-Violet-Default);
    height: 18px;
    width: 18px;
    vertical-align: middle;
    margin: 3px;
    position: relative;
    bottom: 2px;
}

.upload_container {
    margin-top: 4px;
    border-radius: 20px;
    border: 2px dashed var(--Stroke-Default);
    height: 192px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    outline: none;
}

.upload_container:active{
    outline: none;
}

.thumbs_container {
    border-radius: 20px;
    border: 2px dashed var(--Stroke-Default);
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    align-items: start;
    justify-content: left;
    padding: 20px;
}

.content_wrapper {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    overflow-y: auto;
    height: 100%;
}

.content_wrapper__multi_asset_panel {
    gap: 35px;
}

.upload_link:hover, .upload_link:active {
    outline: none;
    color: var(--Accent-Violet-Default);
}

.upload_icon {
    width: 32px;
    height: 32px;
    fill: var(--Accent-Violet-Default);
}

.add_images_button {
    display: flex;
    font-style: normal;
    line-height: normal;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border: none;
    outline: none;
}

.add_images_button svg {
    width: 19px;
    height: 19px;
    top: 7px;
    left: 7px;
    gap: 0;
    fill: var(--Accent-Violet-Default);
}

.upload_preview {
    position: relative;
    display: inline-flex;
    width: 82px;
    height: 82px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid var(--Stroke-Default);
}

.preview_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.upload_delete {
    cursor: pointer;
    position: absolute;
    width: 24px;
    height: 24px;
    background: var(--Fill-White);
    border-radius: 4px;
    right: 4px;
    top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload_delete svg {
    fill: var(--Text-Black-Tertiary);
    width: 16px;
    height: 16px;
}

.upload_inner {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.upload_button {
    cursor: pointer;
    position: relative;
    display: inline-flex;
    width: 82px;
    height: 82px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px dashed var(--Accent-Violet-Default);
    background: var(--Fill-Button);
    justify-content: center;
    align-items: center;
}

.upload_button svg {
    height: 32px;
    width: 32px;
    fill: var(--Accent-Violet-Default);
}

.aboutText {
    margin-top: 12px;
    padding: 0px 0px 18px;
    color: rgba(22, 27, 51, 0.6);
}

.aboutTextWithoutMargin {
    padding: 0px 0px 0px;
    color: rgba(22, 27, 51, 0.6);
}

@media (max-width: 800px) {
    .upload_container{
        height: 100%;
        min-height: 100px;
        padding: 8px;
    }
    .beta {
        margin-left: auto;
        margin-right: auto;
    }
    .aboutText {
        text-align: center;
        width: 100%;
    }
    .content_wrapper__multi_asset_panel{
        margin: -5px 0 0 0;
        gap: 20px
    }
    .content_wrapper_element{
        gap: 5px;
    }
}

@media (max-width: 431px) {
    .upload_container{
        height: 100%;
        min-height: 100px;
        padding: 8px;
    }
    .beta {
        margin-left: auto;
        margin-right: auto;
    }
    .aboutText {
        text-align: center;
        width: 100%;
    }

    h2 {
        text-align: center;
    }
}
