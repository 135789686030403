.video-tutorial{
  color: inherit;
}

.video-tutorial:hover{
  text-decoration: none;
  color: inherit;
  outline: none; 
}

.btnPromoDesktopHeaderTextBlock{
  display: flex;
  flex-direction: column;
  gap: 1px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18.2px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.btnPromoDesktopHeaderMinorText{
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.btnPromoMobileHeaderTextBlock{
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.btnPromoMobileHeaderTextBlock{
  font-size: 13px;
  font-weight: 700;
  line-height: 16.9px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;  
}

.btnPromoMobileHeaderMinorText{
  font-size: 11px;
  font-weight: 600;
  line-height: 14.3px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  
}

.header {
  display: flex;
  width: 100%;
  height: 64px;
  padding: 0 24px;
  align-items: center;
  border-bottom: 1px solid var(--Stroke-Light, rgba(22, 27, 51, 0.1));
  background: var(--Fill-White, #fff);
  z-index: 50;
  position: relative;
}

.btnDwlMobileHeader{
  border-radius: 50%;
  padding: 0 12px;
  width: 40px;
  min-width: 40px;
}

.btnPromoMobileHeaderCircle{
  width: 44px;
  height: 44px;
  padding: 0px;
  min-width: 44px
}

.btnPromoMobileHeader{
  display: flex;
  height: 38px;
  align-items: center;
  text-align: center;
  padding: 4px 13px;
  margin-right: 12px;
  background: 
    radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(0deg, #DB1E0F, #DB1E0F);
  border: 0.5px solid;
  border-image-source: radial-gradient(50% 93.75% at 50% 6.25%, #FDF9FF 0%, rgba(255, 255, 255, 0) 100%);
  border-image-slice: 1;
  color: rgba(255, 255, 255, 1);
}
.btnPromoDesktopHeader:hover, .btnPromoMobileHeader:hover {
  color: #fff;
  background: 
    radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(0deg, rgb(219, 30, 15, 0.9), rgb(219, 30, 15, 0.9));
}

.btnPromoDesktopHeader:active, .btnPromoMobileHeader:active {
  color: #fff;
  background: 
    radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(0deg, rgb(219, 30, 15, 0.9), rgb(219, 30, 15, 0.9));
}

.btnPromoDesktopHeader.disabled, .btnPromoMobileHeader.disabled {
  color: #fff;
  opacity: 0.4;
  background: 
    radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(0deg, rgb(219, 30, 15, 0.9), rgb(219, 30, 15, 0.9));
}

.btnPromoDesktopHeader{
  display: flex;
  height: 48px;
  gap: 5px;
  align-items: center;
  text-align: center;
  padding: 6px 20px;
  margin-right: 12px;
  background: 
    radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(0deg, #DB1E0F, #DB1E0F);
  border: 0.5px solid;
  border-image-source: radial-gradient(50% 93.75% at 50% 6.25%, #FDF9FF 0%, rgba(255, 255, 255, 0) 100%);
  border-image-slice: 1;
  color: rgba(255, 255, 255, 1);
}

.header_btn-promo-desktop{
  display: flex;
  height: 48px;
  gap: 5px;
  align-items: center;
  text-align: center;
  padding: 6px 20px;
  margin-right: 12px;
  background: linear-gradient(88.17deg, rgb(87, 168, 240) 4.2%, rgb(128, 92, 234) 99.52%);
  color: rgba(255, 255, 255, 1);
  border: 0px;
}

.header_btn-promo-mobile{
  display: flex;
  height: 38px;
  align-items: center;
  text-align: center;
  padding: 4px 13px;
  margin-right: 12px;
  background: linear-gradient(88.17deg, rgb(87, 168, 240) 4.2%, rgb(128, 92, 234) 99.52%);
  color: rgba(255, 255, 255, 1);
  border: 0px;
}

.header_btn-promo-mobile_text-block{
  display: flex;
  gap: 8px;
  align-items: center;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  line-height: 16.9px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;  
}

.header_btn-promo-mobile_circle{
  width: 44px;
  height: 44px;
  padding: 0px;
  min-width: 44px;
  display: none;
}

.header_btn-promo-mobile_circle_image{
  height: 40px;
}

.header_btn-promo-desktop:hover {
  background: linear-gradient(88.17deg, rgb(87, 168, 240, 0.8) 4.2%, rgb(128, 92, 234, 0.8) 99.52%);
}

.header_btn-promo-desktop:active {
  background: linear-gradient(88.17deg, rgb(87, 168, 240, 0.8) 4.2%, rgb(128, 92, 234, 0.8) 99.52%);
}

.header_btn-promo-desktop.disabled {
  opacity: 0.4;
  background: linear-gradient(88.17deg, rgb(87, 168, 240, 0.8) 4.2%, rgb(128, 92, 234, 0.8) 99.52%);
}

.header_btn-promo-desktop-text-block{
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18.2px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.header_btn-promo-desktop-minor-text{
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.header_desktop {
  width: 100%;
  justify-content: space-between;
  flex-shrink: 0;
  align-items: center;
}

.header_mobile {
  width: 100%;
  justify-content: space-between;
  flex-shrink: 0;
  align-items: center;
}

.header_logo {
  width: 90px;
  height: 56px;
  flex-shrink: 0;
}

.header_items {
  display: flex;
  height: 64px;
  padding: 4px 8px 4px 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  border-radius: 50px;
  backdrop-filter: blur(0px);
}

.divider {
  width: 2px;
  height: 48px;
  background: var(--Stroke-Light, rgba(22, 27, 51, 0.1));
  margin-left: 24px;
  margin-right: 24px;
}

.burgerButton {
  cursor: pointer;
}

.header_nav_logoAndCreditsBackgroynd {
  display: flex;
  gap: 16px;
  width: 90%;
  background-color: rgba(245, 240, 255, 1);
  border-radius: 20px;
  padding: 12px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 9px;
  margin-bottom: 20px;
}

.avatar {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid var(--Accent-Violet-Default, #9f72ff);
  background: var(--Fill-Background, #f0f0f5);
  cursor: pointer;
}

.menu_list {
  margin: 0;
  padding: 0;
  width: 100%;
  list-style-type: none;
}

.menu_item {
  display: flex;
  color: rgba(22, 27, 51, 1);
  cursor: pointer;
}

.menu_item_out {
  color: rgba(224, 85, 169, 1);
}

.menu_item:nth-child(n + 2) {
  margin-top: 15px;
}

.mobileMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mobileMenu_list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mobileMenu_item {
  display: flex;
  color: rgba(22, 27, 51, 1);
  cursor: pointer;
}

.mobileMenu_item_out {
  color: rgba(224, 85, 169, 1);
}

.mobileMenu_item:nth-child(n + 2) {
  margin-top: 15px;
}

.mobileMenu_line {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.mobileAvatar {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid var(--Accent-Violet-Default, #9f72ff);
  background: var(--Fill-Background, #f0f0f5);
}

.telegram_button_block_inHeader {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.telegram_button_inHeader {
  background: #9f72ff;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  color: #fff;
  text-align: center;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(44, 0, 139, 0.2);
}

.telegram_button_inHeader a {
  color: #fff;
}

.telegram_button_inHeader:hover {
  background: #7948e1;
}

@media screen and (max-width: 800px) {
  .header {
    padding: 0 16px 0 24px;
  }

  .header_items {
    padding: 4px 0px 4px;
  }
}

.minW {
  min-width: 212px;
}


.BackButton {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 64px;
  cursor: pointer;
}

.BackButton svg {
  fill: hsla(259, 72%, 58%, 1);
  height: 32px;
  width: 32px;
}
.BackButton .Text {
  font-size: 16px;
  font-weight: 700;
  line-height: 21.82px;
  color: hsla(230, 40%, 14%, 1);
}

.btnPromoMobileHeaderCircle{
  display: none;
}

@media screen and (max-width: 440px) {
  .btnPromoMobileHeader{
    display: none;
  }
  .btnPromoMobileHeaderCircle{
    display: block;
  }
  .header_btn-promo-mobile{
    display: none;
  }
  .header_btn-promo-mobile_circle{
    display: block;
  }
}
