.Wrapper {
    margin-top: 20px;
}

.InputBlock {
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.promoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ButtonBlock {
    margin-top: 24px;
}


.input {
    border-radius: 16px;
    border: 1px solid var(--Stroke-Light, rgba(22, 27, 51, 0.10));
    background: var(--Fill-White, #FFF);
    height: 40px;
    padding: 12px 16px 12px 16px;
    align-items: center;
    color: #62626F;
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 15.6px */

    width: 221px;
    display: flex;
    gap: 10px;
    align-self: stretch;
    backdrop-filter: blur(15px);
}

.applyButton {
    border-radius: 16px;
    border: 1px solid var(--gradient, #8C56FF);
    display: flex;
    height: 40px;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background: #fff;
    text-align: center;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: var(--gradient, linear-gradient(98deg, #8C56FF 22.71%, #C956FF 73.26%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.applyButton:hover, .applyButton:active {
    background: var(--Accent-Violet-Light);
    color: hsla(259, 100%, 72%, 1);
    background-clip: border-box;
    -webkit-background-clip: border-box;
    -webkit-text-fill-color: hsla(259, 100%, 72%, 1);
}

.cancel {
    cursor: pointer;

}


/* .error {
  border: 1px solid hsla(324, 69%, 61%, 1);
  
} */


.errorText {
    color: var(--Accent-Pink-Dark, #e055a9);
    font-size: 12px;
    text-align: left;
    display: block;
    width: 100%;
    margin-top: 4px;
}

.ActionButton {
    color: var(--accent, #9F72FF);
    text-align: center;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 30px; /* 166.667% */
    letter-spacing: -0.18px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    cursor: pointer;
    display: inline;
}

.FlexBlock {
    display: flex;
    justify-content: center;
}

.AppliedBlock {
    padding: 9px 24px;
    border-radius: 12px;
    background: linear-gradient(98deg, rgba(22, 190, 59, 0.10) 22.71%, rgba(105, 185, 40, 0.10) 73.26%);
    display: flex;
    gap: 6px;
    align-items: center;

    justify-content: center;
    max-width: 322px;
}

.AppliedCodeText {
    background: linear-gradient(98deg, #0EA830 22.71%, #58A817 73.26%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.checkIcon svg {
    fill: rgba(14, 179, 110, 1);
    margin-top: 4px;
}

@media (max-width: 481px) {
    .input {
        width: 100%;
    }
}