.api-access-blok_contacts_button_text{
  background: linear-gradient(98.35deg, #8C56FF 22.71%, #C956FF 73.26%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.api-access-blok_contacts_button{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  
  width: 199px;
  height: 44px;
  padding: 8px 16px;
  border-radius: 16px;

  background: #FFFFFF;
  box-shadow: 0px 4px 13px 0px #D0CAE240;

  text-decoration: none;
}

.api-access-blok_contacts_button:focus {
  outline: none;
}

.api-access-blok_contacts_button:hover {
  text-decoration: none;
  opacity: 0.7;
}

.api-access-blok_contacts_icons {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.api-access-blok_contacts{
  width: 26%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  gap: 20px;
}

.api-access-blok {
  max-width: 881px;
  min-height: 141px;
  display: flex;
  gap: 20px;

  width: 881px;
  background: #F4F3FF;
  padding: 20px;
  border-radius: 16px;
  border: 1px solid #EAE1FF4D;
  margin: 0 auto;
}

.api-access-blok_text{
  width: 74%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  
  font-family: Nunito;
}

.api-access-blok_text_main {
  background: linear-gradient(98.35deg, #8C56FF 22.71%, #C956FF 73.26%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 20px;
  font-weight: 800;
  line-height: 27.28px;
  text-align: left;
}

.api-access-blok_text_minor {
  color: #161B33CC;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.82px;
  text-align: left;
}

@media (max-width: 1120px) {
  .api-access-blok_text_main{
    font-size: 18px;
    line-height: 24.55px;
  }

  .api-access-blok_contacts_icons{
    display: none;
  }

  .api-access-blok{
    flex-direction: column;
    width: auto;
    max-width: 362px;
    padding: 24px;
  }

  .api-access-blok_text{
    width: 100%;
  }

  .api-access-blok_contacts{
    width: 100%;
  }

  .api-access-blok_contacts_button{
    width: 100%;
  }
}