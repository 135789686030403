@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

:root {
  --Fill-White: #fff;
  --fill-white-10: rgba(255, 255, 255, 0.1);
  --Fill-Black: #161b33;
  --Accent-Violet: #9f72ff;
  --Accent-Violet-Light: #F5F0FF;
  --Accent-Violet-Default: #9f72ff;
  --Accent-Violet-Dark: #7948e1;
  --Accent-Pink-Default: #ed79bf;
  --Accent-Pink-Dark: #e055a9;
  --Accent-Green-Default: #3fd898;
  --Accent-Green-Dark: #0eb36e;
  --Accent-Blue-Default: #69a8f2;
  --Accent-Blue-Dark: #3888e6;
  --Fill-Background: #f0f0f5;
  --Gradient-Accent: linear-gradient(98deg, #8c56ff 22.71%, #c956ff 73.26%);
  --Gradient-Violet: linear-gradient(111deg, #854cfe 14.26%, #9f72ff 83.15%);
  --Gradient-Pink: linear-gradient(109deg, #ed638c 13.68%, #ed78be 86.67%);
  --Gradient-Green: linear-gradient(111deg, #08be92 16.83%, #3ed797 85.22%);
  --Gradient-Blue: linear-gradient(109deg, #3a73e3 16.26%, #68a7f2 88.44%);
  --Stroke-Default: rgba(22, 27, 51, 0.2);
  --Stroke-White: #fff;
  --stroke-white-30: rgba(255, 255, 255, 0.3);
  --Stroke-Active: #161b33;
  --Stroke-Light: rgba(22, 27, 51, 0.1);
  --Text-Black-Primary: #161b33;
  --Text-Black-Secondary: rgba(22, 27, 51, 0.6);
  --Text-White-Primary: #fff;
  --Text-White-Secondary: rgba(255, 255, 255, 0.8);
  --Text-White-Tertiary: rgba(255, 255, 255, 0.5);
  --Text-Black-Tertiary: rgba(22, 27, 51, 0.4);
  --Fill-Button: #f5f0ff;
  --Fill-Error: #fdf2f9;
  --radial: radial-gradient(
          49.86% 49.86% at 49.86% 92.68%,
          #812FFB 0%,
          #6C00E7 75.47%,
          #550AC0 100%
  );
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

input:focus,
input:focus-visible,
button:focus-visible {
  outline: none;
  border: none;
  /* Add any custom focus styles here if desired */
}

input:focus {
  border: 1px solid var(--Stroke-Default, rgba(22, 27, 51, 0.2));
}

.hidden {
  display: none;
}

.titleXL {
  font-family: Nunito, sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 800;
  line-height: 68px;
  letter-spacing: -1.28px;
}

.titleL {
  font-family: Nunito, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 56px;
  letter-spacing: -0.96px;
}

.titleForgot {
  font-family: Nunito, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  letter-spacing: -0.96px;
  text-align: center;
  flex-grow: 1;
}

.titleM {
  font-family: Nunito, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  letter-spacing: -0.32px;
}

.titleS {
  font-family: Nunito, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.28px;
}

.paragraphL {
  font-family: 'PT Sans', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.paragraphM {
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.bp5-button-text, .bp5-control, .polotno-panel-container {
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.paragraphMCenter {
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.paragraphS {
  font-family: 'PT Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bodyL {
  font-family: Nunito, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.bodyM {
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.bodyS {
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

.colorTextWhitePrimary {
  color: var(--Text-White-Primary, #fff);
}

.colorTextBlackPrimary {
  color: var(--Text-Black-Primary, #161b33);
}

.colorTextBlackSecondary {
  color: var(--Text-Black-Secondary, rgba(22, 27, 51, 0.6));
}

.colorTextBlackTertiary {
  color: var(--Text-Black-Tertiary);
}

.colorAccentVioletDefault {
  color: var(--Accent-Violet-Default, #9f72ff);
}

.colorAccentVioletDark {
  color: var(--Accent-Violet-Dark, #7948e1);
}

.colorAccentPinkDark {
  color: var(--Accent-Pink-Dark, #7948e1);
}

.colorAccentVioletGradient {
  background: var(--radial, radial-gradient(49.86% 49.86% at 49.86% 92.68%, #812FFB 0%, #6C00E7 75.47%, #550AC0 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.center {
  text-align: center;
}

body {
  display: flex;
  /* background: #f00f5; */
  background-color: #fff;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  max-height: 100%;
}

.footer {
  display: flex;
  width: 100%;
  padding: 40px 80px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 40px;
  background: var(--Accent-Violet, #9f72ff);
}

.footer_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.footer_row_items {
  display: flex;
  align-items: center;
  gap: 40px;
  border-radius: 50px;
}

.footer_row_logo {
  width: 90px;
  height: 48px;
}

.footer_copyright {
  text-align: center;
}

.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  z-index: 12;
}

.modal.active {
  transform: scale(1);
}

.downloadButtonSpan {
  margin-left: 7px;
}

.downloadPopupSvg {
  margin-bottom: -3px;
}

.menu {
  position: absolute;
  top: calc(100% + 5px);
  right: 0px;
  padding: 25px;
  background-color: white;
  border: 0.5px solid rgba(22, 27, 51, 0.1);
  box-shadow: 0px 4px 8px rgba(2, 41, 88, 0.16);
  border-radius: 8px;

  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden;
  transition: 0.3s;
}

.menu.active {
  opacity: 1;
  visibility: visible;
}

.header_nav {
  z-index: 105;
  position: fixed;
  top: 64px;
  right: -72vw;
  width: 72vw;
  height: calc(100vh - 63px);
  height: calc(100dvh - 63px);
  background-color: rgba(255, 255, 255, 1);
  transition: transform 0.2s linear;

  /* height: calc(var(--vh, 1vh) * 100 - 63px); */
  /* height: 89dvh; */
}

.header_nav.active {
  transform: translateX(-100%);
}

.header_background {
  z-index: 104;
  position: fixed;
  top: 64px;
  width: 100vw;
  height: calc(100vh - 63px);
  height: calc(100dvh - 63px);
  background-color: rgba(22, 27, 51, 0.4);
  transition:
    opacity 0.2s,
    visibility 0.2s;
  opacity: 0;
  visibility: hidden;
}

.header_background.active {
  opacity: 1;
  visibility: visible;
}

.mainScreen {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  max-height: 100%;
  overflow: hidden;
}

.regText {
  margin-top: -20px;
}

.buttonLeft {
  display: inline-flex;
  height: 56px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 50px;
  width: 50%;
  cursor: pointer;
  background: rgba(245, 240, 255, 1);
}

.buttonRight {
  display: flex;
  height: 56px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  background: var(--Gradient-Accent, linear-gradient(98deg, #8c56ff 22.71%, #c956ff 73.26%));
  cursor: pointer;
  width: 46%;
}

a.buttonLeft:active,
a.buttonLeft:hover,
a.buttonLeft {
  text-decoration: none;
  color: var(--Accent-Violet-Default, #9f72ff);
}

a.buttonRight:active,
a.buttonRight:hover,
a.buttonRight {
  text-decoration: none;
  color: var(--Text-White-Primary, #fff);
}

.lineUploader {
  width: 44%;
  height: 0;
  border: 1px solid #c4c4c4;
  margin: 3px;
  margin-bottom: auto;
  margin-top: auto;
}

.uploader_content_divider {
  width: 42%;
  display: inline-flex;
}

.orInUploader {
  margin: 0 10px;
}

.hidden-mobile {
  display: none;
}

.hidden-desktop {
  display: flex;
}

.divider-section {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
}

.line {
  flex-grow: 1;
  height: 1px;
  width: 144px;
  border: 1px solid #c4c4c4;
}

.text-between-lines {
  padding: 0 10px;
  text-transform: lowercase;
}

@media screen and (max-width: 800px) {
  .hidden-desktop {
    display: none;
  }

  .hidden-mobile {
    display: flex;
  }
}

.sm-only {
  display: none;
}

@media (max-width: 450px) {
  .sm-only {
    display: block;
  }
}

.sm-hidden {
  display: block;
}

@media (max-width: 450px) {
  .sm-hidden {
    display: none;
  }
}