.buttonContainer {
    display: flex;
    gap: 8px;
    flex-direction: column;
    width: 100%;
}

.buttonLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px
}

.priceLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
}