.dialog {
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transform: scale(1); */
  z-index: 103;
}

.inner_wrapper {
  border-radius: 60px;
  background-color: white;
  /*max-height: 486px;*/
  max-width: 686px;
  border: 1px solid rgba(22, 27, 51, 0.1);
}

.inner_wrapper_wide {
  min-width: 300px;
  min-height: 200px;
}

.top_dock {
  display: flex;
  justify-content: end;
  flex-direction: row;
  align-items: center;
  margin: 15px 20px 0 0;
}

.cursor_pointer {
  cursor: pointer;
}

.container {
  padding: 0 40px 40px 40px;
}

.body {
  display: block;
}

/*  Mobile styles. Must be deleted in the future */
/* fit to editor mobile view */
@media (max-width: 690px) {
  .inner_wrapper {
    margin: 0 16px;
  }
  h1 {
    font-size: 22px;
  }
}

@media (max-width: 431px) {
  .container {
    padding: 0 20px 40px 20px;
  }
  .inner_wrapper {
    margin: 0 5px;
  }
}

@media (max-height: 570px) {
  .inner_wrapper{
    transform: scale(0.8);
    min-width: 350px;
  }
}
