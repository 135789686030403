.body-block_image {
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 332px;
}

.body-block_main-text {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  font-weight: 800;
}

.body-block_main-text b {
  background: linear-gradient(90deg, #8C57FF 0%, #C957FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.minor-text-block {
  padding: 0 90px;
  text-align: center;
  display: block;
  margin: 13px auto 30px;
  color: var(--Text-Black-Primary, #161b33);
  white-space: break-spaces !important;
}

.minor-text-block_first-minor-text{
  font-family: PT Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.7px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #161B3399;
  margin-bottom: 0px
}

.minor-text-block_first-minor-text b{
  background: radial-gradient(49.86% 49.86% at 49.86% 92.68%, #812FFB 0%, #6C00E7 75.47%, #550AC0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.minor-text-block_second-minor-text {
  max-width: 410px;
  margin: auto;

  font-family: PT Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.7px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #161B3399;
}

.minor-text-block_second-minor-text b{
  background: radial-gradient(49.86% 49.86% at 49.86% 92.68%, #812FFB 0%, #6C00E7 75.47%, #550AC0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.timer-block {
  position: relative;
  width: 258px;
  height: 61px;
  border-radius: 16px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
  margin: -40px auto 26px;
  box-shadow: 10px 15px 30px 0px rgba(175, 145, 193, 0.1), -4px -4px 4px 0px rgba(255, 255, 255, 0.5);
}

.timer-block::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 16px; 
  padding: 1px;
  background: linear-gradient(98.35deg, rgba(140, 86, 255, 0.4) 22.71%, rgba(201, 86, 255, 0.4) 73.26%);
  mask: 
  linear-gradient(#fff 0 0) content-box, 
  linear-gradient(#fff 0 0);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
  z-index: -1;
}

.body-block_main-text__blood-weight {
  font-weight: 800 !important;
}

.button-block {
  display: flex;
  grid-column-gap: 4%;
  white-space: nowrap;
  justify-content: center;
  flex-direction: row;
}

.button-block_buy-button{
  width: 75%;
  font-weight: 800;
}

@media (max-width: 800px) {
  .body-block_image {
    width: 241px;
  }

  .body-block_main-text{
    font-size: 23px;
    font-weight: 700;
    line-height: 27.6px;
  }

  .minor-text-block_first-minor-text p {
    margin-bottom: 5px;
  }

  .minor-text-block_second-minor-text {
    font-size: 16px;
  }

  .button-block_buy-button{
    width: 100%;
  }
}

@media (max-width: 662px) {
  .minor-text-block {
    padding: 0;
    margin-left: -5px;
    margin-right: -5px;
  }
  .button-block {
    flex-direction: row;
    gap: 8px;
  }
  .timer-block{
    width: 218px;
  }
}

@media (max-width: 462px) {
  .body-block{
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (max-width: 380px) {
  .timer-block{
    margin: -5px auto 26px;
  }
}














