.timerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
}

.timeBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
  width: 64px;
}

.timeValue {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 800;
  line-height: 32.74px;
  letter-spacing: -0.02em;
  color: rgba(61, 64, 78, 1);
}

.timeLabel {
  font-family: Nunito;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.37px;
  color: rgba(22, 27, 51, 0.6);
  margin-top: -3px;
}

.colon {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 800;
  line-height: 32.74px;
  letter-spacing: -0.02em;
  margin-top: -13px;
  color: rgba(61, 64, 78, 1);
}

@media (max-width: 662px) {
  .timeBlock {
    width: 55px;
  }
}
