.pink_icon {
    background: linear-gradient(109.04deg, #ED638C 13.68%, #ED78BE 86.67%);
}

.buttons {
    display: flex;
    flex-direction: row;
    flex-flow: nowrap;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.body_modal{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.upload_information_container {
    padding: 15px;
    border-radius: 20px;
    border: 2px dashed var(--Stroke-Default);
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 4px;
    outline: none;
    flex-wrap: wrap;
}

.upload_information_container:active{
    outline: none;
}

@media (max-width: 800px) {
    .upload_information_container {
        overflow-y: auto;
        height: 300px;
    }
}