.toggleLabel {
  margin-right: 18px;
}

.toggle {
  margin-left: 8px;
}

.removeBackgroundToggle {
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
}

.centerBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.removeBackgroundIcon {
  fill: var(--Accent-Violet-Default);
  height: 18px;
  width: 18px;
  vertical-align: middle;
}

@media screen and (max-width: 450px) {
  .removeBackgroundToggle {
      margin-bottom: 26px;
  }
  .SubHeader {
    display: none;
  }
}