.TabContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
}
.Tab {
  /* background-color: hsla(260, 100%, 97%, 1); */
  border-radius: 18px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  outline: 2px solid transparent;
}

.Tab:hover {
  outline: 2px solid #8c56ff;
}

.active {
  background: var(--Gradient-Accent, linear-gradient(98deg, #8c56ff 22.71%, #c956ff 73.26%));
  outline: 2px solid transparent;
}

.active:hover {
  outline: 2px solid transparent;
}

.size-m {
  padding: 12px 24px 12px 24px;
  min-width: 156px;
  color: var(--Text-Black-Secondary, rgba(22, 27, 51, 0.60));
  text-align: center;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.size-s {
  padding: 8px 16px 8px 16px;
  font-size: 12px;
  min-width: 148px;
}

.TabLabel {
  color: hsla(230, 40%, 14%, 0.4);
  font-weight: 700;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  display: flex;
  gap: 4px;
}

.TabLabel svg {
  margin-bottom: -5px;
}

.Tab.active > .TabLabel {
  /* color: hsla(259, 100%, 72%, 1); */
  color: hsla(0, 0%, 100%, 1);
}

.Tab.size-s > .TabLabel {
  font-size: 12px;
}

.DiscountLabel {
  background: hsla(324, 76%, 70%, 1);
  padding: 8px 12px 8px 12px;
  color: #fff;
  margin-top: -12px;
  margin-bottom: -12px;
  margin-left: 6px;
  display: inline;
  border-radius: 18px;
  font-weight: 700;
  font-size: 12px;
}

@media (max-width: 720px) {
  .TabContainer {
    justify-content: start;
    overflow-x: scroll;
  }
}

.toggleWrapper {
  padding: 1px;
  border-radius: 20px;
  border: 1px solid var(--Stroke-Light, rgba(22, 27, 51, 0.10));
  background: var(--Fill-White, #FFF);
  display: inline-flex;
  height: 47px;
  align-items: center;
  flex-shrink: 0;
}


@media (max-width: 481px) {
  .size-m {
    min-width: 136px;
    font-size: 14px;
  }
}