.CardPlan {
  position: relative;
  border-radius: 20px;
  border: 1px solid var(--Stroke-Light, rgba(22, 27, 51, 0.10));
  background: var(--Fill-White, #FFF);
  padding: 32px 24px 32px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-bottom: 4px;
  max-width: 344px;
  background: hsla(0, 0%, 100%, 1);
}

.highlighted {
  border: 2px solid var(--gradient, #8C56FF);
  box-shadow: 0px 58px 114px 0px rgba(206, 171, 255, 0.11), 0px 34.15px 59.462px 0px rgba(206, 171, 255, 0.08), 0px 17.539px 27.907px 0px rgba(206, 171, 255, 0.06), 0px 7.053px 12.221px 0px rgba(206, 171, 255, 0.05), 0px 1.578px 5.29px 0px rgba(206, 171, 255, 0.03);
}

.clientTypeTip {
  margin: 0 auto;
  color: #62626F;
  text-align: center;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 15.6px */
  max-width: 264px;
  margin-bottom: 16px;
}

.Header {
  color: hsla(259, 100%, 72%, 1);
  background: linear-gradient(98deg, #8C56FF 22.71%, #C956FF 73.26%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 8px;
  margin-bottom: 4px;
}

.SubHeader {
  position: absolute;
  top: 0;
  left: 118px;
  display: flex;
  height: 24px;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  color: #FFF;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 116.667% */
  border-radius: 0px 0px 12px 12px;
  background: linear-gradient(111deg, #854CFE 14.26%, #9F72FF 83.15%);
}

.Price {
  text-align: center;
  font-weight: 800;
  color: hsla(230, 40%, 14%, 1);
  font-size: 32px;
  margin-bottom: 24px;
  line-height: 36px;
}

.buttonWrap {
  margin-bottom: 24px;
}

.PricePostfix {
  color: var(--Text-Black-Secondary, rgba(22, 27, 51, 0.60));
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.PriceDescription {
  color: var(--Text-Black-Secondary, rgba(22, 27, 51, 0.60));
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Divider {
  height: 1px;
  display: block;
}

.Divider::after {
  content: ' ';
  width: 100%;
  display: block;
  border-bottom: 1px solid hsla(230, 40%, 14%, 0.1);
}

/* ul.FeaturesList {
} */

.FeaturesList {
  margin-top: 24px;
  color: rgba(22, 27, 51, 0.80);
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.FeatureIcon svg {
  display: inline;
  width: 20px;
  height: 20px;
}
.Feature {
  display: flex;
  gap: 8px;
  /* align-items: center; */
}

.buttonWithTooltip {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  overflow: visible;
}

@media (max-width: 1120px) {
  ul.FeaturesList {
    margin-top: -14px;
  }
  .CardPlan {
    max-width: 362px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltip_text {
  visibility: hidden;
  width: 316px;

  filter: drop-shadow(0px 1.094px 3.989px rgba(102, 16, 160, 0.02)) drop-shadow(0px 5px 11.212px rgba(102, 16, 160, 0.03)) drop-shadow(0px 12.656px 30.08px rgba(102, 16, 160, 0.03)) drop-shadow(0px 25px 69px rgba(102, 16, 160, 0.05));
  border-radius: 8px;
  border: 1px solid #CAB3EB;
  background: #fcfcfc;
  box-shadow: 33.928px 42.011px 114px 0px rgba(190, 170, 214, 0.17), 13.068px 16.182px 36.311px 0px rgba(190, 170, 214, 0.10);

  color: rgba(22, 27, 51, 0.80);
  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 16.9px */

  text-align: center;
  border-radius: 8px;
  padding: 16px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -158px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip_not_chrome_macos {
  background: rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: blur(35px) !important;;
}

.tooltip .tooltip_text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #CAB3EB transparent transparent transparent; /* Make the arrow */
}

.tooltip .tooltip_text::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -4px;
  border-width: 4px;
  border-style: solid;
  border-color: #fcfcfc transparent transparent transparent; /* Make the arrow */
  z-index: 2;
}

.buttonRedesign {
  border-radius: 16px;
  border: none;
}

.tooltip:hover .tooltip_text,
.tooltip:focus .tooltip_text {
  visibility: visible;
  opacity: 1;
}

.tooltipFallback {
  font-size: 12px;
  text-align: center;
  margin-top: 12px;
  color: var(--Text-Black-Tertiary, rgba(22, 27, 51, 0.4));
}

.tooltipFallback{
  margin-bottom: -5px !important;
}

.DiscountBlock {
  display: flex;
  flex-direction: column;
}

.DiscountBlockPrice {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #62626F;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  gap: 8px;
}

.BeforePrice {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #62626F;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.strike {
  position: relative;
  display: inline-block;
}

.strike::after {
  content: '';
  position: absolute;
  top: 50%; /* Adjust to align with the text */
  left: 0;
  right: 0;
  height: 11px; /* Match the SVG's height */
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="66" height="11" viewBox="0 0 66 11" fill="none"><path d="M1 10L65 1" stroke="url(%23paint0_linear_1_369)" stroke-width="1.5" stroke-linecap="round"/><defs><linearGradient id="paint0_linear_1_369" x1="1" y1="5.5" x2="65" y2="5.5" gradientUnits="userSpaceOnUse"><stop stop-color="%23713EFF"/><stop offset="1" stop-color="%23CD2F64"/></linearGradient></defs></svg>') no-repeat center;
  background-size: contain;
  transform: translateY(-50%);
  pointer-events: none;
}

.DiscountPercentBubble {
  border-radius: 10px;
  background: var(--radial, radial-gradient(49.86% 49.86% at 49.86% 92.68%, #812FFB 0%, #6C00E7 75.47%, #550AC0 100%));
  padding: 6px 8px;
  color: #fff;
}

.inactive {
  color: #b2b4c0;
}

@media (max-width: 481px) {
  .Feature{
    position: relative;
  }
  .tooltip {
    position: static;
  }

  .tooltip .tooltip_text::after {
    display: none;
  }
  
  .tooltip .tooltip_text::before {
    display: none;
  }
}
