.invoice-text-blok{
  margin: 0 auto 44px;
  padding: 15px 40px;
}

.invoice-text-blok_text{
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  color: #161B3399;
}

.invoice-text-blok__blue{
  background: radial-gradient(49.72% 49.72% at 50% 100%, #812FFB 0%, #550AC0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 400;
}

.invoice-text-blok__blue:focus {
  outline: none;
}

@media (max-width: 1120px) {
  .invoice-text-blok {
    max-width: 362px;
  }
}

@media (max-width: 481px) {
  .invoice-text-blok {
    max-width: 362px;
    width: 100%;
  }
}