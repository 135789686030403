.pink_icon {
    background: linear-gradient(109.04deg, #ED638C 13.68%, #ED78BE 86.67%);
}

.buttons {
    display: flex;
    flex-direction: row;
    flex-flow: nowrap;
    align-items: center;
    justify-content: center;
    gap: 12px;
}