.container {
    width: 100%;
    display: flex;
    gap: 12px;
    z-index: 1000;
}

.content {
    background: rgba(245, 240, 255, 1);
    padding: 20px;
    margin: 12px;
    border-radius: 20px;
    gap: 4px;
    width: 100%;
    flex-direction: column;
    display: flex;
}

.headerSection {
    display: flex;
    justify-content: space-between;
}

.headerSection svg:hover {
    cursor: pointer;
}

.header {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 700;
    line-height: 21.82px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(121, 72, 225, 1);

}

.typography {
    font-family: PT Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.7px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.checkboxLabel {
    font-family: PT Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(22, 27, 51, 1);
}

.button {
    margin-top: 8px;
}